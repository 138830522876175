import React from 'react'
import { Link } from 'gatsby'

import '../../static/styles/resource-banner.css'

export default () => (
  <div className="resource-banner-container container">
    <div className="row">
      <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
        <Link
          title="The Definitive Guide to Cold Email in 2025"
          to="/cold-email"
          className="link"
        >
          <h1>The Definitive Guide to Cold Email in 2025</h1>
        </Link>
      </div>
      <div className="col-12 col-lg-6 offset-xl-1 col-xl-5 d-flex flex-column justify-content-between align-items-stretch align-items-lg-start">
        <p>
          Everything there is to know about cold emails & follow-ups to
          consistently get insane reply rates.
        </p>
        <Link
          title="Learn More"
          className="btn btn-primary purple-btn"
          to="/cold-email"
        >
          Learn More
        </Link>
      </div>
    </div>
  </div>
)
